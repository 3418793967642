import React from "react";
import {Progress} from "@chakra-ui/react";
import {Box} from "@chakra-ui/react";
import {Flex, Spacer} from "@chakra-ui/react";
import {ArrowBackIcon} from "@chakra-ui/icons";
import {Link} from "react-router-dom";

export const Steps = (props) => {
    return (
        <Box>
            <Progress value={props.currentStep} max={props.maxSteps}/>
            <Flex>
                <Box py="2">
                    {props.backLink &&
                    <Link to={props.backLink}>
                        <ArrowBackIcon w={6} h={6}/>
                    </Link>
                    }
                </Box>
                <Spacer/>
                <Box py="2">
                    {props.currentStep}/{props.maxSteps}
                </Box>
            </Flex>
        </Box>
    );
};
