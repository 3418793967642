import {Box, Container, Heading} from "@chakra-ui/react";
import {Steps} from "../steps/Steps";

function Step(props) {
    return (
        <Container p={[1, 5]}>
            <Box
                background={"white"}
                borderWidth="1px"
                borderRadius="lg"
                p={[3, 5]}
                mt={1}
                textAlign={"center"}
            >
                <div className="steps-counter-container">
                    <Steps currentStep={props.currentStep} maxSteps="12" backLink={props.backLink}/>
                </div>

                {props.heading &&
                    <Heading as={"h2"} size={"lg"} mb={5}>
                        {props.heading}
                    </Heading>}

                {props.description && <p>{props.description}</p>}

                {props.children}
            </Box>
        </Container>
    );
}

export default Step;
