import {Box, Button, Container, Heading, Image, Text} from "@chakra-ui/react";
import packageImage from '../../assets/home/bottle.jpg';
import {useNavigate} from "react-router-dom";
import useStore from "../../storage/DataStore";
import {FormattedMessage} from "react-intl";

function Home() {
    let navigate = useNavigate();
    const nextStep = function (gender) {
        useStore.setState({
            gender: gender
        });

        navigate('/step1', {replace: true});
    }

    return (
        <Container p={[1, 5]}>
            <Box
                background={"#f4f6eb"}
                borderWidth="1px"
                borderRadius="lg"
                textAlign={"center"}
            >
                <Heading p={5} pt={[2,4]} pb={0} fontSize={[20, 32]}>
                    <FormattedMessage id="home.heading" />
                </Heading>

                <Box>
                    <Image src={packageImage} alt={'Grenosan®'}/>
                </Box>

                <Box p={5} background={'#fff'}>
                    <Text pb={[3,4]}>
                        <FormattedMessage id="home.text" values={{
                            b: (chunks) => <strong>{chunks}</strong>
                        }} />
                    </Text>

                    <Button p={10} mb={[2,4]} onClick={() => nextStep('male')} width={'100%'} background={'#d9f1ef'}
                            fontSize={'xl'} color={'black'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                             className="bi bi-gender-male" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                  d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"/>
                        </svg>

                        <Text pl={2}><FormattedMessage id="home.gender.male" /></Text>
                    </Button>

                    <Button p={10} onClick={() => nextStep('female')} width={'100%'} background={'#f1608c'} color={'white'}
                            fontSize={'xl'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                             className="bi bi-gender-female" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                  d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5z"/>
                        </svg>

                        <Text pl={2}><FormattedMessage id="home.gender.female" /></Text>
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default Home;
