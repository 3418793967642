import Step from "../../components/step/Step";
import {SuccessStory} from "../../components/success-story/SuccessStory";
import success1 from "../../assets/step9/success1.jpg";
import {Button} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";

function Step10() {
    let navigate = useNavigate();
    const intl = useIntl();

    return (
        <Step currentStep={10}
              backLink={'/step9'}>

            <SuccessStory title={intl.formatMessage({id: 'step10.headline'})}
                          description={
                              <FormattedMessage
                                  id="step10.text"
                                  values={{
                                      b: (chunks) => <strong>{chunks}</strong>
                                  }}
                              />
                          }
                          image={success1}/>

            <Button bg="brand"
                    color={'white'}
                    size="md"
                    mt={5} w={'100%'}
                    onClick={() => {
                        navigate('../step11', {replace: true})
                    }}
                    type="submit">
                <FormattedMessage id="form.button.next" />
            </Button>
        </Step>
    );
}

export default Step10;